@import './__config';

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  transition: background 500ms;
  background-color: #fff;
}

.root {
  position: relative;
  font-family: $alumni-font-family;
  font-size: 14px;
  line-height: 1.4;
  color: #fff;
}
