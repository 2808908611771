@import 'styles/_variables';
@import 'styles/_mixins';

.RootContainer {
  padding: 12px 35px 0;

  @include mobile {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.header,
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer {
  margin-top: 15px;
}

.title {
  font-weight: bold;
  color: #787D99;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  @include mobile {
    margin-right: 10px;
    font-size: 13px;
  }
}

.Contribute {
  @include buttonPrimarySolid {
    min-width: 90px;
    height: 28px;
    font-size: 12px;

    @include mobile {
      align-self: flex-start;
    }
  }
}

.ContributorAvatars {
  @include mobile {
    display: none;
  }
}

.Progress {
  max-width: 250px;

  @include mobile {
    max-width: 225px;
    margin: 0 auto;
  }
}
