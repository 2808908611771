@import 'styles/_variables';
@import 'styles/_mixins';

.RootContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.header,
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &_text {
    white-space: nowrap;
    font-weight: bold;
    white-space: nowrap;
    line-height: 1;
    text-align: center;
    min-width: 70px;
    max-width: 70px;

    &:first-child {
      min-width: 56px;
      max-width: 56px;
    }

    &:nth-child(2) {}
    &:last-child {}
  }
}

.header {
  &_text {
    color: #5A42EF;
    font-size: 14px;
  }
}

.footer {
  &_text {
    color: #787D99;
    font-size: 10px;
  }
}

.progress {
  margin: 5px 0;
  height: 8px;
  width: 100%;
  border-radius: 10px;
  background-color: #E1DCFD;
  transition: width 1000ms ease-out;

  &_bar {
    border-radius: inherit;
    min-width: 8px;
    height: 100%;
    background-color: #5A42EF;
  }
}
