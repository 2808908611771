
.Button {
  position: relative;
  transition-property: background-color, color, opacity;
  transition-duration: 220ms;
  transition-timing-function: ease-in-out;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
}

.isProcessing,
.isDisabled {
  pointer-events: none;
}

.isProcessing {
  opacity: 0.7;
}

.isDisabled {
  opacity: 0.5;
}
