@import '../../_config';

.root {
  display: flex;
  flex-direction: column;
  background-color: $alumni-blue-light;
  margin: 20px 15px;
  padding: 30px 20px;
  border-radius: 10px;
}

.title {
  font-size: 36px;
  text-align: center;
  font-family: $alumni-font-family-title;
}

.subtitle {
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  margin: 10px 0 25px;
  color: #fff;
}

.campaigns {
  display: flex;
  flex-direction: column;
}
