@import '../__config';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $skema-beige;
  pointer-events: none;
  user-select: none;

  > div {
    padding-top: 7px;
    align-self: stretch;
    transform: translateY(45%);

    > img {
      max-height: 32px;
    }
  }
}

.hide {
  transition-property: opacity;
  opacity: 0;
}

.animate {
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-name: SkemaEaseUp;
  animation-fill-mode: forwards;

  @keyframes SkemaEaseUp {
    0% {
      transform: translateY(45%);
    }
    100% {
      transform: translateY(0);
    }
  }
}