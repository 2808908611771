@import 'styles/_variables';
@import 'styles/_mixins';

.Root {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 1px 10px rgba(#000, 0.15);
  width: 100%;
  height: 580px;
}

.Cropper {}

.options {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.zoom {
  height: 40px;
  margin: 0 20px;
  padding: 0;
}

.rotate {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.buttonCancel {
  @include buttonPrimaryHollow {
    width: 124px;
    height: 34px;
    margin-right: 20px;
  }
}

.buttonSubmit {
  @include buttonPrimarySolid {
    width: 124px;
    height: 34px;
  }
}
