@import 'styles/_variables';
@import 'styles/_mixins';

.Root {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 135px;
  height: 135px;
  background: #F0F2FF;
  box-shadow: 0 1px 10px rgba(#000, 0.15);
  border-radius: 8px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
}

.options {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  width: 100%;
  height: 100%;
  transition: opacity 320ms;
  border-radius: inherit;
  background-color: rgba(#000, 0.5);

  &:hover {
    opacity: 1;
  }
}

.edit,
.modify {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.remove {
  position: absolute;
  top: -8px;
  right: -8px;
  cursor: pointer;
}

.progress {
  position: absolute;
  font-size: 15px;
  font-weight: bold;
  color: #000;
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
