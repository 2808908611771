@import 'styles/_variables';
@import 'styles/_mixins';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background-color: #fff;
  max-width: 800px;
  text-align: center;
  padding: 50px 0;
  margin: 60px auto;
  border-radius: 10px;
  overflow: hidden;
  color: #2A1C5B;

  @include mobile {
    padding: 25px 10px;
    margin: 25px 10px;
  }
}