@import 'styles/_variables';
@import 'styles/_mixins';

.root {
  align-items: center;
}

.title1 {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 10px;

  @include mobile {
    font-size: 16px;
  }
}

.title2 {
  font-size: 12px;
  margin-bottom: 40px;

  @include mobile {
    font-size: 10px;
    margin-bottom: 20px;
  }
}

.email {
  max-width: 350px;
  margin-bottom: 36px;

  @include mobile {
    max-width: none;
    margin-bottom: 15px;
  }
}

.codeCharacters {
  position: relative;
  display: flex;
  align-items: center;
  height: 75px;
  margin-bottom: 50px;

  @include mobile {
    height: 44px;
    margin-bottom: 30px;
    width: 100%;
  }

  > div,
  > input {
    height: 100%;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    font-size: 44px;
    color: #2A1C5B;
    width: 56px;
    margin: 0 6px;
    border: 2px solid #787D99;
    background-color: #fff;

    @include mobile {
      font-size: 20px;
      width: auto;
      flex: 1;
      margin: 0 3px;
    }
  }

  > input {
    font-size: 1px;
    position: absolute;
    padding: 0;
    width: 100%;
    border: 0 none;
    background-color: transparent;
    opacity: 0;

    &:focus {
      outline: none;
    }
  }
}

.submit {
  background-color: #5A42EF;
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
}