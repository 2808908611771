@import 'styles/_variables';
@import 'styles/_mixins';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title1 {
  font-size: 40px;
  font-weight: bolder;
  margin-bottom: 16px;

  @include mobile {
    font-size: 22px;
  }
}

.title2 {
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 15px;

  @include mobile {
    font-size: 16px;
  }

  > span {
    margin-left: 7px;

    > strong {
      margin-left: 7px;
      color: #5A42EF;
    }
  }  
}

.title3 {
  font-size: 18px;
  margin-bottom: 50px;

  @include mobile {
    font-size: 12px;
  }

  > strong {
    color: #5A42EF;
  }
}
