
.root {
  display: flex;
  flex-direction: column;
  border: 1px solid #2A1C5B;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
  cursor: pointer;
  background-color: #fff;
  box-shadow: 4px 4px 4px 0px #CA3A404D;
}

.header {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;

  > img {
    margin-right: 10px;
    min-width: 50px;
    max-width: 50px;
    height: 50px;
    object-fit: contain;
    border: 1px solid #2A1C5B;
    border-radius: 3px;
  }

  .body {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }
}

.title {
  font-size: 16px;
  font-weight: 900;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subtitle {
  font-size: 10px;
  font-weight: 700;
}

.description {
  font-size: 12px;
  font-weight: 400;
  white-space: pre-wrap;
}