@import 'styles/_variables';
@import 'styles/_mixins';

.RootContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  border: 2px solid #5A42EF;
  padding: 25px 14px;
  margin: 10px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  color: #5A42EF;
}

.text {
  font-size: 12px;
  font-weight: bold;
  color: #787D99;
  margin: 20px 0 25px;
}

.button {
  @include buttonPrimaryHollow {
    width: 124px;
    height: 35px;
    font-size: 12px;
    font-weight: bold;
  }
}
