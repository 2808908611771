@import 'styles/_variables';
@import 'styles/_mixins';

.Button {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin: 0 !important;
  }
}

.Menu {
  position: fixed;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 20px 20px rgba(#000, 0.3);
  animation-duration: 120ms;
  animation-timing-function: ease-out;
  animation-delay: 0;
  animation-iteration-count: 1;
  animation-name: MenuAnimation;
  animation-fill-mode: backwards;
  z-index: 99999;

  @keyframes MenuAnimation {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  @include mobile {
    left: auto !important;
    right: 15px !important;
    max-width: 70% !important;
  }
}

.MenuItem {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 6px;
  margin-bottom: 5px;
  font-size: 14px;
  border-radius: 5px;
  color: #787D99;
  text-align: left;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    color: #fff;
    background-color: #5A42EF;
    background: linear-gradient(0deg, #5A42EF, #5A42EF);
  }
}

.extra {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: -105%;
  margin-right: 25px;
  background-color: #fff;
  box-shadow: 0 20px 20px rgba(#000, 0.3);
  border-radius: 10px;
  width: 154px;

  &_title,
  &_yes,
  &_no {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    height: 33px;
    color: #787D99;
  }

  &_title {
    font-weight: bold;
    border-bottom: 1px solid lightgray;
  }

  &_yes {
    color: #EB5757;

    &:hover {
      background-color: rgba(#EB5757, 0.05);
    }
  }

  &_no {
    &:hover {
      background-color: rgba(#787D99, 0.05);
    }
  }
}
