@import 'styles/_variables';
@import 'styles/_mixins';

.Root {
  position: relative;
}

.video {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  width: auto;
  height: auto;

  // react player only
  > div {
    width: 100%;
  }

  video {
    width: 100%;
    height: 100%;
    max-height: 530px;
  }
}

.preview {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.gallery {
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;

  &_image {
    position: relative;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      max-height: 300px;
      object-fit: cover;
      cursor: zoom-in;
    }
  }

  &_toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    font-weight: bold;
    font-size: 42px;
    cursor: pointer;
    background: linear-gradient(0deg, rgba(90, 66, 239, 0.65), rgba(90, 66, 239, 0.65));
  }
}

.imageWrapper {
  background-color: #fff;
}

.image {
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
  max-height: 630px;
  object-fit: cover;
  cursor: zoom-in;
}

.text {
  display: flex;
  margin: 0 5px;
  padding: 20px 30px;
  font-size: 16px;
  color: #291E6E;
  text-align: justify;
  line-height: 20px;
  background-color: #ECF1FF;
  word-wrap: break-word;
  word-break: break-word;

  &_tags {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
    word-wrap: normal;
    word-break: keep-all;
    line-height: 15px;
  }
}

.tag {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  padding: 2px 8px;
  margin: 3px;
  background-color: #87BBBE;
  border-radius: 30px;
}

.singleTag {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  line-height: 1;
  height: 21px;
  padding: 0 8px;
  background-color: #5282C9;
  border-bottom-left-radius: 5px;

  @include mobile {
    display: none;
  }
}

.linkImage {
  display: block;
}

.link {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #ECF1FF;
  text-decoration: none;
  cursor: pointer;

  &_body {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    line-height: 1.2;
    flex: 1;
    overflow: hidden;
  }

  &_title {
    font-size: 16px;
    font-weight: bold;
    color: #291E6E;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &_description {
    max-height: 34px;
    overflow: hidden;
    font-size: 14px;
    color: #291E6E;
    margin-top: 3px;
    word-wrap: break-word;
    word-break: break-all;
  }
}
