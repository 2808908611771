@import '../../../_config';

.root {
  display: flex;
  flex-direction: column;
  // border-radius: 5px;
  border: 1px solid $alumni-pink;
  color: $alumni-blue-light;
  margin-bottom: 20px;
  cursor: pointer;
  background-color: #fff;
  box-shadow: 4px 4px 4px 0px #CA3A404D;
}

.image {
  height: 140px;
  object-fit: cover;
}

.body {
  padding: 15px;
}

.title {
  font-size: 24px;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  font-family: $alumni-font-family-title;
  margin-bottom: 5px;
}

.subtitle {
  font-size: 14px;
  font-weight: bold;
  color: $alumni-pink;
  margin-bottom: 15px;
}

.expand {
  text-decoration: underline;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

.description {
  font-size: 12px;
  font-weight: 400;
  white-space: pre-wrap;
}