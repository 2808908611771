@import 'styles/_variables';
@import 'styles/_mixins';

.root {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  overflow-y: auto;
  padding-bottom: 50px;
  padding-top: $AppBar-height + 50px;

  @include mobile {
    padding-top: $AppBar-height-mobile + 50px;
  }

  &__withoutPadding {
    padding: 50px 0 !important;
  }
}

.overlayTransparent,
.overlayBlur {
  @include modalOverlay;
}

.overlayBlur {
  top: $AppBar-height;

  @include mobileHeaderNavigation {
    top: $AppBar-height-mobile;
  }

  &__withoutPadding {
    top: 0 !important;
  }
}

.overlayTransparent {
  background-color: transparent;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #fff;
  padding: 35px 50px;
  max-width: 450px;
  width: 100%;
  border-radius: 10px;
  animation-duration: 200ms;
  animation-timing-function: ease-out;
  animation-delay: 100ms;
  animation-iteration-count: 1;
  animation-name: ModalPopup;
  animation-fill-mode: backwards;

  @keyframes ModalPopup {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  @include mobile {
    margin: 0 10px;
    padding: 30px 15px;
  }
}
