@import '../_config';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #3C55C840;
}

.body {
  max-width: 320px;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 10px;
}

.title {
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}

.buttons {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.abort,
.confirm {
  min-width: auto;
  flex: 1;
  max-width: 40%;
}

.abort {
  color: inherit;
  background: #fff;
  border: 2px solid #E7433C;
}

