@import 'styles/_variables';
@import 'styles/_mixins';

.container {
  display: flex;
  align-items: center;
  text-align: left;
  cursor: pointer;

  img {
    display: flex;
  }
}

.text {
  margin-left: 15px;
  font-size: 14px;
  color: #4B4B4B;

  @include mobile {
    font-size: 12px;
  }
}
