@import 'styles/_variables';
@import 'styles/_mixins';

.Root {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  box-shadow: 0 0 1px 1px rgba(#EB5757, 0.6);
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  line-height: 1.4;
  font-size: 12px;
  max-width: 250px;
  font-weight: bold;
  text-align: center;
  transition: opacity 240ms;
}

.arrow {
  position: absolute;
  bottom: -10px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid rgba(#EB5757, 0.8);
}
