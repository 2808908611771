@import 'styles/_variables';
@import 'styles/_mixins';

.placeholder {
  height: $AppBar-height;
  opacity: 0;

  @include mobileHeaderNavigation {
    height: $AppBar-height-mobile;
  }
}

.Root {
  position: fixed;
  top: 0;
  left: 0;
  height: $AppBar-height;
  width: 100%;
  border-bottom: 1px solid rgba(#787d99, 0.2);
  background-color: #fff;
  z-index: 9999;

  @include mobileHeaderNavigation {
    height: $AppBar-height-mobile;
  }
}

.desktop,
.mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  &_left,
  &_center,
  &_right {
    display: flex;
    align-items: center;
    height: 100%;
  }
}

.desktop {
  &_left,
  &_right {
    flex: 2;
  }

  &_center {
    justify-content: center;
    flex: 1;
  }

  &_right {
    justify-content: flex-end;
  }

  @include mobileHeaderNavigation {
    display: none;
  }
}

.mobile {
  position: relative;
  display: none;

  &_right {
    justify-content: flex-end;

    &__isActive {
      flex: 1;
    }
  }

  @include mobileHeaderNavigation {
    display: flex;
  }
}

/* DESKTOP */
.Search {
  margin-left: 50px;
}

.buttonManifesto,
.buttonImpactNav {
  @include buttonPrimaryText {
    height: 100%;
    padding: 0 25px;
    margin-right: 25px;
    font-size: 14px;
    font-weight: bold;
  }
}

.buttonImpactNav {
  &__isSticky {
    color: #4B579A;
    text-decoration: underline;
  }
}

.buttonLogin {
  @include buttonPrimarySolid {
    width: 123px;
    height: 33px;
    margin-right: 25px;
  }
}

.Profile,
.ImpactNav {
  position: fixed;
  top: 80px;
  max-height: 0;
  z-index: 9999;
  transition: transform 160ms, max-height 160ms, padding 160ms;
  transform: scale(0);

  > div:first-child {
    position: absolute;
    top: -28px;
    height: 35px;
  }

  &__isVisible {
    transform: scale(1);
    max-height: 1000px;
  }
}

.Profile {
  right: 10px;
  transform-origin: top right;

  > div:first-child {
    right: 0;
    width: 320px;
  }
}

.ImpactNav {
  right: 100px;
  max-width: 300px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 1px 1px 3px rgba(#000, 0.3);
  transform-origin: top;

  > div:first-child {
    right: 0;
    left: 0;
    margin: 0 auto;
    width: 250px;
  }

  &__isVisible {
    padding: 30px 25px;
  }
}

.user {
  display: flex;
  align-items: center;
  height: 100%;
  padding-right: 50px;

  &_name {
    margin-left: 15px;
    font-size: 14px;
    font-weight: bold;
    color: #787d99;
  }

  &_notifications {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    font-size: 11px;
    color: #fff;
    font-weight: bold;
    background-color: #c85d5d;
  }
}

/* DESKTOP */

/* MOBILE */
.buttonMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 100%;
}

.logoSmall {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 100%;
  }
}

.SearchMobile {
  flex: auto;
  transition: max-width 360ms;
  margin-right: 10px;
  max-width: 31px;

  &__isFocused {
    max-width: 900px;
  }
}
/* MOBILE */
