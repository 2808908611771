@import 'styles/_variables';
@import 'styles/_mixins';

@mixin _mobile {
  @include mobile(1180px) {
    @content;
  }
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: #F0F2FF;
}

.RootComponent {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding-top: 150px;
  min-height: 100%;

  @include _mobile {
    padding: 50px 20px;
    flex-direction: column;
  }
}

.left {
  margin-right: 100px;

  @include _mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-right: 0;
  }

  img {
    @include _mobile {
      max-width: 220px;
    }
  }
}

.title {
  color: #787D99;
  font-size: 30px;
  font-weight: bold;

  @include _mobile {
    font-size: 24px;
  }
}

.text {
  color: #787D99;
  font-size: 18px;
  margin: 25px 0 32px;

  @include _mobile {
    font-size: 16px;
  }
}

.button {
  @include buttonPrimarySolid {
    font-size: 16px;
    font-weight: bold;
    width: 152px;
    height: 36px;
  }
}

.right {
  @include _mobile {
    display: none;
  }
}
