@import '../_config';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
  background-color: $skema-beige;
  box-shadow: 0 0 30px 0 #2A1C5B66;
  border-radius: 10px;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
}

.title {
  font-size: 40px;
  font-weight: 900;
  padding: 26px 0;
}

.videos {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.video {
  margin: 0 10px;
}

.background,
.play {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $skema-red-gradient;
}

.background {
  z-index: 9;
  opacity: 0.6;
}

.play {
  background-image: url('./play.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 74px;
  cursor: pointer;
  z-index: 10;
}