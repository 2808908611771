@import 'styles/_variables';
@import 'styles/_mixins';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 800px;
  padding: 20px 12px;
  border-radius: 10px;
  background-color: #fff;
}

.title {
  font-weight: bold;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
  color: #787D99;
  margin: 25px 0;
  color: #2A1C5B;

  @include mobile {
    margin: 15px 0;
  }
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 153px;
  height: 153px;
  border: 1px solid #2A1C5B;

  > img {
    max-width: 100%;
  }
}

.text {
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  color: #787D99;
  margin: 15px 0 40px;
  padding: 0 25px;
  text-align: center;
  color: #2A1C5B;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.cancel,
.submit {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 13px;
  text-align: center;
  height: 35px;
  width: 190px;
  border-radius: 10px;
}

.submit {
  background-color: #5A42EF;
  color: #fff;
  margin-bottom: 15px;
}

.cancel {
  color: #2A1C5B;

  &:hover {
    text-decoration: underline;
  }
}
