@import 'styles/_variables';
@import 'styles/_mixins';

.Root {
  position: fixed;
  bottom: 25px;
  right: 25px;
  max-width: 356px;
  background-color: #fff;
  box-shadow: 0 0 25px rgba(#000, 0.25);
  border-radius: 10px;
  z-index: 99999;
}

.body {
  padding: 20px;
}

.title {
  color: #291E6E;
  font-size: 20px;
  font-weight: bold;
  line-height: 23px;
  margin-bottom: 10px;
}

.text {
  font-size: 12px;
  line-height: 14px;
  color: #787D99;

  a {
    margin-left: 5px;
    color: inherit;
  }
}

.footer {
  display: flex;
  align-items: center;
}

.buttonRefuse,
.buttonAccept {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #E9E9E9;
  flex: 1;
  height: 37px;
}

.buttonRefuse {
  font-size: 14px;
  color: #787D99;
  border-right: 1px solid #E9E9E9;
}

.buttonAccept {
  font-size: 14px;
  font-weight: bold;
  color: #291E6E;
}
