@import 'styles/_variables';
@import 'styles/_mixins';

.RootContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 35px;
  height: 60px;

  @include mobile {
    padding: 0 10px;
    height: 50px;
  }
}

.buttons {
  display: flex;
  align-items: center;

  @include mobile {
    width: 100%;
    justify-content: space-between;
  }
}

.like,
.comment,
.share,
.interest {
  @include buttonPrimaryBackgroundFade {
    line-height: 1;
    padding: 0 4px;
    height: 30px;
    margin-right: 20px;

    &:hover {
      background-color: #F1F5FF;
    }

    @include mobile {
      margin-right: 5px;
    }

    &_text {
      margin-left: 10px;
      font-size: 12px;
      font-weight: bold;
      color: #787D99;

      @include mobile {
        margin-left: 7px;
        font-size: 10px;
      }
    }
  }
}

.like {
  justify-content: flex-start;
  width: 50px;
  border-radius: 50px;
}

.comment,
.share {
  @include mobile {
    img {
      max-width: 22px;
    }
  }
}

.comment {
  img {
    width: 23px;
  }
}

.share {
  position: relative;
  overflow: visible !important;

  &_image {
    max-width: none !important;
    position: absolute;
    top: 100%;
    height: 230px;
    z-index: 9999;
    border-radius: 10px;
  }
}

.interest {
  &_loader {
    padding: 5px;
  }

  &_text {
    @include mobile {
      display: none;
    }
  }
}

.commentCount {
  @include buttonSecondaryText {
    height: 30px;
    font-size: 12px;
    white-space: nowrap;

    @include mobile {
      display: none;
    }
  }
}
