@import 'styles/_variables';
@import 'styles/_mixins';

.Modal {
  padding: 25px;
  max-width: 475px;

  @include mobile {
    padding: 10px;
  }
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.info {
  margin-left: 18px;

  @include mobile {
    margin-left: 10px;
  }
}

.name {
  color: #787d99;
  font-weight: bold;
  margin-bottom: 6px;
}

.location {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #787d99;

  img {
    margin-right: 8px;
  }
}

.noData {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  color: #4b4b4b;
  padding: 6px 10px;
  border: 1px solid rgba(#787d99, 0.3);
  border-radius: 10px;

  img {
    margin-left: 8px;
    margin-right: 18px;
  }

  span {
    margin: 0 3px;
    color: #5a42ef;
  }
}

.menu {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  border-radius: 30px;
}

.description {
  font-size: 12px;
  color: #4b4b4b;
  text-align: justify;
  margin-top: 10px;
}

.items {
  border: 1px solid rgba(#787d99, 0.3);
  border-radius: 10px;
  padding: 10px;
  overflow-y: auto;
  width: 100%;
  max-height: 370px;
  margin-top: 20px;

  &_title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 12px;

    &_amount {
      background-color: #5a42ef;
      border-radius: 20px;
      height: 20px;
      padding: 0 12px;
      font-size: 14px;
      color: #fff;
      line-height: 1.4;
      margin-right: 10px;
    }

    span {
      margin: 0 5px;
      color: #5a42ef;
    }
  }
}

.item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  background-color: #f0f2ff;
  margin-bottom: 5px;

  &_body {
    margin-left: 10px;
  }

  &_title {
    font-size: 12px;
    font-weight: bold;
    color: #4b4b4b;
  }

  &_description {
    font-size: 12px;
    color: #4b4b4b;
  }
}

.experiences {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.experience {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #fff;
  border-radius: 5px;
  height: 23px;
  padding: 0 8px;
  margin-right: 10px;
  text-transform: capitalize;
}

.close {
  position: absolute;
  top: -10px;
  left: -10px;
  cursor: pointer;
}
