@import 'styles/_variables';
@import 'styles/_mixins';

$icon-width: 36px;

.Root {
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
}

.icon {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: $icon-width;
  height: 100%;
  pointer-events: none;

  img {
    width: 28px;
  }
}

.input {
  padding-left: $icon-width;
  border: 0 none;
  height: 33px;
  font-size: 14px;
  font-weight: bold;
  color: #787D99;
  background-color: #fff;
  box-shadow: none;
  outline: none;
  border: 0 none;
  width: 100%;

  &__isFocus {
    font-weight: normal !important;
  }
}

.results {
  position: absolute;
  top: 100%;
  width: 100%;
  padding: 3px 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #fff;
  box-shadow: 1px 1px 1px rgba(#000, 0.2);
}

.item {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 4px;
  padding: 2px 6px;
  font-size: 12px;
  font-weight: bold;
  color: #787D99;

  &:hover {
    color: #4B579A;
    background-color: rgba(#5a42ef, 0.1);
  }

  img {
    object-fit: cover;
    width: 30px;
    height: 30px;
    border-radius: 6px;
    overflow: hidden;
    margin-right: 6px;
  }
}

.empty {
  padding: 2px 10px;
  font-size: 12px;
  color: #787D99;
}
