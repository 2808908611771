@import 'styles/_variables';
@import 'styles/_mixins';

.RootContainer {
  background-color: #fff;
  width: 100%;
  padding: 50px;
  border-radius: 10px;

  @include mobile {
    padding: 20px 10px;
  }
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.iconSuccess {
  max-width: 88px;
  margin-bottom: 20px;

  @include mobile {
    min-width: 60px;
    min-height: 60px;
    max-width: 60px;
    max-height: 60px;
  }
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: #4B4B4B;
  text-align: center;

  @include mobile {
    font-size: 18px;
  }
}

.text,
.text2 {
  font-size: 14px;
  color: #4B4B4B;
  text-align: center;

  @include mobile {
    font-size: 12px;
  }
}

.text {
  margin: 20px 0;

  span {
    margin: 0 5px;
  }
}

.text2 {
  span {
    display: block;
    color: #5A42EF;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.InputPassword {
  margin-bottom: 15px;
}

.submit {
  @include buttonPrimarySolid {
    min-width: 123px;
    width: 123px;
    margin: 30px auto 0;
    height: 35px;
    font-size: 12px;
    font-weight: bold;
    align-self: flex-end;
  }
}
