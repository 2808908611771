@import 'styles/_variables';
@import 'styles/_mixins';

.bodyForgotPassword,
.body,
.BannerLeft,
.BannerRight {
  position: relative;
  height: 100%;
  width: 100%;
  max-height: 670px;
  overflow-y: auto;
  border-radius: 10px;

  @include mobile {
    height: auto;
  }
}
.BannerLeft,
.BannerRight {
  min-width: 218px;
  max-width: 218px;

  @include mobile {
    display: none;
  }
}
.BannerLeft {
  margin-right: 12px;
}
.BannerRight {
  margin-left: 12px;
}
.bodyForgotPassword,
.body {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: #fff;
}
.bodyForgotPassword {
  height: auto;
  max-width: 588px;
  max-height: none;

  @include mobile {
    margin: 0 10px;
  }
}
.body {
  max-width: 630px;
  padding: 40px 70px;

  @include mobile {
    padding: 20px 10px;
    margin: 0 10px;
  }

  &_content {
    width: 100%;
  }
}

.title {
  max-width: 260px;
  margin: 0 auto 25px;
  text-align: center;
  font-size: 23px;
  color: #4b4b4b;
  font-weight: bold;

  @include mobile {
    max-width: none;
    padding: 0 20px;
    font-size: 18px;
  }
}

.subTitle {
  margin: 20px 0;
  text-align: center;
  font-size: 12px;
  color: #797979;

  @include mobile {
    font-size: 11px;
  }
}

.successRegister {
  margin: 0 auto;
  text-align: center;
  font-size: 12px;
  color: #797979;
  font-weight: bold;

  @include mobile {
    font-size: 11px;
  }
}
