@import 'styles/_variables';
@import 'styles/_mixins';

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.Root {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  max-width: 363px;
  width: 100%;
  margin: 90px auto;
  padding: 25px;
  border-radius: 10px;
}

.title {
  color: #5A42EF;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 22px;
}

.button {
  @include buttonPrimarySolid {
    font-size: 12px;
    font-weight: bold;
    width: 153px;
    height: 35px;
  }
}
