@import 'styles/_variables';
@import 'styles/_mixins';

.title {
  font-size: 16px;
  font-weight: bold;
  color: #5A42EF;
  margin-bottom: 25px;
}

.buttonSubmit {
  @include buttonPrimarySolid {
    width: 124px;
    height: 35px;
    font-size: 16px;
  }
}
