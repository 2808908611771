@import 'styles/_variables';
@import 'styles/_mixins';

.yourGift {
  font-weight: bold;
  font-size: 16px;
  color: #787D99;
  margin-bottom: 30px;
  margin-top: 40px;
}

.yourDonation {
  font-size: 18px;
  font-weight: bold;
  color: #787d99;
  margin-top: 15px;

  > span {
    margin-left: 5px;
  }
}

.yourDonationDone {
  font-size: 18px;
  font-weight: bold;
  color: #787d99;
  margin-bottom: 60px;
  margin-top: 20px;
  text-align: center;
}

.doneOk {
  margin-top: 60px;
}

.Credits {
  position: absolute;
  top: 10px;
  right: 10px;
}

.wallletDone {
  font-size: 18px;
  font-weight: bold;
  color: #5a42ef;
}

.amount {
  margin: 12px 0 25px;
  font-size: 24px;
  font-weight: bold;
  color: #5a42ef;

  @include mobile {
    margin-bottom: 15px;
  }
}

.customInput {
  margin: 35px 0 20px;

  @include mobile {
    margin: 25px 0 20px;
  }
}

.infoTitle {
  align-self: flex-start;
  font-size: 16px;
  font-weight: bold;
  color: #291E6E;
  margin-bottom: 20px;
}

.infoText {
  font-size: 14px;
  color: #291E6E;
  line-height: 20px;

  img {
    margin-top: 2px;
    margin-right: 7px;
  }

  span {
    font-weight: bold;
  }
}

.infoTextRow {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
}

.submit {
  margin-top: 30px;

  @include buttonPrimarySolid {
    width: 123px;
    height: 35px;
  }
}
