@import '../../_config';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
  background-color: $skema-beige;
  box-shadow: 0 0 30px 0 #2A1C5B66;
  border-radius: 10px;
  text-align: center;
  padding: 100px 20px;

  > span {
    max-width: 240px;
    font-size: 22px;
    font-weight: 900;
  }
}
