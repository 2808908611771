@import '../../_config';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $alumni-blue;
}

.body {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}

.language {
  font-size: 24px;
  font-family: $alumni-font-family-title;
}