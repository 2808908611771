@import '../__config';

.root {
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  // min-width: 124px;
  padding: 0 40px;
  font-size: 16px;
  // border-radius: 10px;
  color: #fff;
  cursor: pointer;
  background: $alumni-pink;
  box-shadow: 0px 4px 4px 0px #00000040;
  font-family: $alumni-font-family-title;
}