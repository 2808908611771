@import 'styles/_variables';
@import 'styles/_mixins';

.RootContainer {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 600px;
  padding: 20px 30px;
  padding-left: 20px;
  border-radius: 10px;
  box-shadow: 1px 1px 3px rgba(#000, 0.3);
  background-color: #fff;
  animation-duration: 220ms;
  animation-timing-function: ease-out;
  animation-delay: 0;
  animation-iteration-count: 1;
  animation-name: UserMenuAppear;
  animation-fill-mode: forwards;
  transform-origin: top;

  @keyframes UserMenuAppear {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
}

.body {
  display: flex;
  align-items: stretch;
}

.Navigation {
  padding-right: 30px;
  max-width: 250px;
}

.userContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 30px;
  border-left: 1px solid #4B579A;
}

.user {
  display: flex;
  align-items: flex-start;

  &_left {
    width: 246px;
  }

  &_right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.title,
.titleMyProjects {
  font-size: 16px;
  font-weight: bold;
  color: #4B579A;
  margin-bottom: 15px;
}
.titleMyProjects {
  margin-top: 10px;
}

.myProfile,
.settings,
.community {
  @include buttonSecondaryText {
    justify-content: flex-start;
    font-size: 14px;
    height: 25px;
  }
}
.myProfile {
  &_notifications {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    margin-left: 10px;
    border-radius: 16px;
    font-size: 11px;
    color: #fff;
    font-weight: bold;
    background-color: #C85D5D;
  }
}
.myTransparency {
  @include buttonSecondaryText {
    justify-content: flex-start;
    text-align: left;
    font-size: 14px;
    margin-top: 7px;
    height: 30px;

    img {
      margin-right: 16px;
    }
  }
}

.username {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #4B579A;
  margin: 6px 0 15px;
}

.line {
  margin: 15px 0 25px;
  min-height: 1px;
  width: 100%;
  background-color: #4B579A;
}

.titleSmall {
  color: #4B579A;
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 15px;
}

.projectDetail {
  @include buttonSecondaryText {
    justify-content: flex-start;
    min-height: 30px;
    max-height: 30px;
    width: 100%;
    font-size: 14px;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }

    span {
      margin-left: 15px;
      margin-right: 3px;
      color: #4431BD;
    }

    &_arrow {
      margin-left: 8px;
    }
  }
}

.projects {
  width: 100%;
}
.project {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  height: 37px;
  color: #4B579A;

  &:hover {
    text-decoration: underline;
  }

  &_logo {
    margin-right: 15px;
  }
}

.admin {
  @include buttonPrimaryHollow {
    height: 42px;
    width: 100%;
    margin-top: 30px;
  }
}

.logout {
  @include buttonSecondaryText {
    align-self: center;
    margin-top: 30px;
    padding: 10px 15px;
    font-size: 12px;
    border-radius: 10px;
    transition: background-color 320ms;

    &:hover {
      background-color: rgba(#000, 0.05);
    }

    &:active {
      background-color: rgba(#000, 0.1);
    }

    img {
      margin-right: 12px;
    }
  }
}

.gifts {
  margin-top: 15px;
}

.gift {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  color: #4B579A;

  > div:last-child {
    font-size: 14px;
    font-weight: bold;
    margin-left: 10px;

    > strong {
      margin-left: 5px;
      color: #5A42EF;
    }
  }
}