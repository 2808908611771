@import 'styles/_variables';
@import 'styles/_mixins';

.root {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.left,
.right {
  display: flex;
  align-items: center;

  &.active {}
}

.toggle {
  display: flex;
  align-items: center;
  border-radius: 39px;
  margin: 0 15px;
  background-color: #fff;
}

.slider {
  transition: transform 160ms ease-in-out;
  background-color: #5A42EF;
  border-radius: 100px;

  &.active {}
}
