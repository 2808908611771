@import 'styles/_variables';
@import 'styles/_mixins';

.RootContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  padding: 17px 0;
  border: 1px solid rgba(#000, 0.1);
  border-radius: 10px;

  @include mobile {
    flex-direction: column;
    padding: 10px 0 5px;
  }
}

.ButtonContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  border: none;
  background: none;
  cursor: pointer;
  z-index: 1;
  pointer-events: all;
}

.label {
  padding: 0 20px;
  font-size: 15px;
  font-weight: bold;
  color: #787D99;
  background-color: #fff;
  text-align: center;

  @include mobile {
    font-size: 11px;
    top: -7px;
  }
}

.buttonNext {
  @include buttonPrimarySolid {
    width: 202px;
    height: 42px;
    font-size: 16px;
    margin-top: 30px;
  }
}

.buttonSkip {
  @include buttonPrimaryHollow {
    width: 154px;
    height: 35px;
    font-size: 12px;
    margin-top: 30px;

    @include mobile {
      margin-top: 20px;
    }
  }
}