@import 'styles/_variables';
@import 'styles/_mixins';

.root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.headerOnly {
  top: $AppBar-height !important;
  height: 271px !important;

  @include mobile {
    top: $AppBar-height-mobile !important;
  }
}