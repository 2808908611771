@import '../__config';

.root {
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  min-width: 124px;
  padding: 0 10px;
  font-size: 14px;
  font-weight: 700;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  background: $skema-red-gradient;
  box-shadow: 0 4px 4px 0 #00000040;
}