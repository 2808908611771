@import 'styles/_variables';
@import 'styles/_mixins';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.imageDesktop,
.imageMobile {
  border: 6px solid #2A1C5B;
}
.imageDesktop {
  @include mobile {
    display: none;
  }
}
.imageMobile {
  display: none;

  @include mobile {
    display: block;
  }
}

.text {
  text-align: center;
  color: #2A1C5B;
  font-size: 16px;
  font-weight: bold;
  margin: 25px auto;
  max-width: 250px;
  line-height: 24px;
}

.submit {
  @include buttonPrimarySolid {
    padding: 0 25px;
    font-size: 20px;
    font-weight: bold;
  }
}