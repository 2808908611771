@import 'styles/_variables';
@import 'styles/_mixins';

.Root {
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(#000, 0.15);
  background-color: #fff;
  font-family: $font-family-primary;
  margin-bottom: 20px;
  width: 100%;
}

.line {
  height: 1px;
  margin: 15px 60px 0;
  background-color: #CFD2E3;

  @include mobile {
    margin: 15px 30px 0;
    background-color: #F1F1F5;
  }
}
