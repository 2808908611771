@import 'styles/_variables';
@import 'styles/_mixins';

/* TOGGLE */

.buttonToggleModal {
  &__isWhite {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 3px;
    color: #5a42ef;
    height: 30px;
    font-weight: bold;
    font-size: 14px;
    padding: 0 10px;

    img {
      margin-right: 10px;
    }
  }

  &__isBrand {
    @include buttonPrimarySolid {
      border-radius: 3px;
      color: #fff;
      height: 30px;
      font-size: 14px;
      padding: 0 10px;

      img {
        margin-right: 10px;
      }
    }
  }
}

/* MODAL */

.Modal {
  max-width: 500px;
}

.title {
  font-size: 16px;
  font-weight: bold;
  color: #787d99;
  margin-bottom: 40px;

  @include mobile() {
    margin-bottom: 25px;
  }
}

.buttonRouteContainer {
  display: flex;
  align-items: center;
  width: 100%;
}

.buttonRoute {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex: 1;
  height: 170px;
  padding: 0 20px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0 0 15px rgba(#000, 0.15);
  background-color: #f5f7fd;
  color: #291e6e;

  &:first-child {
    margin-right: 25px;

    @include mobile() {
      margin-right: 15px;
    }
  }

  &:hover {
    background-color: $color-primary;
    color: #fff;
  }

  &:active {
    box-shadow: inset 0 0 15px rgba(#fff, 0.5);
  }

  @include mobile() {
    width: 100%;
    height: 100px;
    font-size: 12px;
    margin: 0 8px;
    padding: 0 8px;
  }
}

.yourDonation {
  font-size: 18px;
  font-weight: bold;
  color: #787d99;
}

.amount {
  margin: 12px 0 25px;
  font-size: 24px;
  font-weight: bold;
  color: #5a42ef;

  @include mobile {
    margin-bottom: 15px;
  }
}

.customInput {
  margin: 35px 0;

  @include mobile {
    margin: 25px 0 35px;
  }
}

.submit {
  @include buttonPrimarySolid {
    width: 123px;
    height: 35px;
  }
}
