@import 'styles/_variables';
@import 'styles/_mixins';

.RootContainer {
  display: flex;
  align-items: center;
}

.Avatar {
  margin-right: 5px;
}

.inputContainer {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.input {
  display: block;
  width: 100%;
  height: 32px;
  padding: 8px 90px 8px 15px;
  border: 0 none;
  border-radius: 10px;
  width: 100%;
  background-color: #F5F7FD;

  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.submit {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  height: 100%;
  width: 90px;
  font-size: 12px;
  color: rgb(90, 66, 239);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
