@import '../../../__config';

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 420px;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 35px;
  padding: 7px;
  padding-bottom: 0;
  overflow: hidden;
  background: $skema-red-gradient;

  > video {
    border-radius: inherit;
    width: 100%;
  }
}

.play {
  position: absolute;
  background-image: url('./play.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 74px;
  width: 100%;
  height: 100%;
  cursor: pointer;

  &.hidden {
    opacity: 0;
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: 20px;
  min-height: 53px;
  line-height: 30px;
  padding: 7px 0;
  font-weight: 900;
  color: #fff;
  background: $skema-red-gradient;
}