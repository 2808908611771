@import '../../_config';

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 420px;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 35px;
  padding: 7px;
  padding-bottom: 0;
  z-index: 1;
  overflow: hidden;
  transition: transform 320ms;
  background: $skema-red-gradient;

  &.playing {
    z-index: 10;
    transform: scale(1.2) translateY(-30px);
  }

  > video {
    border-radius: inherit;
    width: 100%;
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: 20px;
  min-height: 53px;
  line-height: 30px;
  padding: 7px 0;
  font-weight: 900;
  color: #fff;
  background: $skema-red-gradient;
}

