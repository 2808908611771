$AppBar-height: 70px;
$AppBar-height-mobile: 54px;

$font-family-primary: Helvetica, sans-serif;

// NOTE: if you change this, global search for $Input-height
// as this is also used on some raw css files!
$Input-height: 42px;
$Input-Small-height: 35px;
$Input-padding: 20px;
$Input-padding-mobile: 10px;
$Input-border-radius: 10px;
$Input-font-size: 14px;
$Input-font-family: $font-family-primary;

$color-primary: #5a42ef;
$color-primary-dark: #4431bd;
$color-lightgray: #f5f7fd;
$color-gray: #787d99;
$color-yellow: #efd33e;
$color-green: #4ae0c6;
$color-pink: #e840a1;
$color-red: #eb5757;

$color-font-primary: #787d99;
