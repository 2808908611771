@import 'styles/_mixins';
@import '../_config';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
  background-color: $alumni-blue-light;
  box-shadow: 0 0 30px 0 #2A1C5B66;
  border-radius: 10px;
  font-size: 32px;
  text-align: center;
}

.title {
  font-size: 36px;
  padding: 26px 0;
  max-width: 700px;
  font-family: $alumni-font-family-title;
}

.campaigns {
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
  width: 100%;
  padding: 0 5px;
}

.campaign {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 5px;
}

.percent {
  margin-bottom: 20px;
  line-height: 1.2;
  font-size: 26px;
  font-weight: 800;
  text-align: center;

  > div {
    font-size: 18px;
  }
}

.media {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  // max-width: 380px;
  // min-width: 380px;
  min-height: 100px;
  border-radius: 10px;
  margin-bottom: 35px;
  padding: 7px 2px;
  padding-bottom: 0;
  z-index: 1;
  transition: transform 320ms;
  overflow: hidden;
  background: #fff;

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    > img {
      // border-radius: 10px;
      overflow: hidden;
      max-width: 100%;
      // max-height: 100%;
    }
  }

  // > video {
  //   border-radius: inherit;
  //   width: 100%;
  // }
}

// .overlay {
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   margin: 0 7px;
//   background-color: #5A42EF80;
//   z-index: 2;
// }

.campaignName {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 8px 0;
  // font-weight: 900;
  font-family: $alumni-font-family-title;
  color: $alumni-pink;
  z-index: 3;
  max-width: 230px;
  line-height: 1;
  font-size: 18px;

  @include mobile {
    font-size: 12px;
  }
}

