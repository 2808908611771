@import 'styles/_variables';
@import 'styles/_mixins';

.background,
.RootContainer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  transition: transform 420ms;
  transform: translateX(-100%);

  &__isVisible {
    transform: translateX(0);
  }
}

.background {
  z-index: 9995;
  width: 100%;
  background-color: rgba(#000, 0.4);
}

.RootContainer {
  z-index: 9999;
  width: 90%;
  padding: 30px 20px;
  overflow-y: auto;
  background-color: #5A42EF;
}

.header {
  display: flex;
  align-items: center;
}
.user {
  display: flex;
  flex-direction: column;
  margin-left: 15px;

  &_name {
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 5px;
  }
}
.title {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  margin: 20px 0;

  &_back {
    width: 34px;

    img {
      width: 20px;
    }
  }

  &_line {
    margin-left: 20px;
    flex: 1;
    height: 1px;
    background-color: #fff;
  }
}



.gift {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  color: #fff;

  .giftCredits {
    border: 1px solid #fff;
  }

  > div:last-child {
    font-size: 14px;
    font-weight: bold;
    margin-left: 10px;

    > strong {
      margin-left: 5px;
    }
  }
}


.items {
  display: flex;
  flex-direction: column;
}
.item {
  position: relative;
  height: 36px;

  &_back,
  &_button {
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 14px;
    font-weight: bold;

    img {
      margin-right: 12px;
    }
  }

  &_back {
    color: #fff;
  }

  &_button {
    position: absolute;
    top: 0;
    left: -7px;
    padding-left: 7px;
    width: calc(100% + 7px);
    opacity: 0;
    color: #5A42EF;
    background-color: #fff;
    border-radius: 3px;

    // &:hover {
    //   opacity: 1;
    // }
  }
}

.project {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  height: 37px;
  border-radius: 4px;

  // &:hover {
  //   text-decoration: underline;
  // }

  &_logo {
    margin-right: 15px;
  }
}

.login {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #fff;
  height: 42px;
  color: #5A42EF;
  font-size: 16px;
  font-weight: bold;
  border-radius: 10px;
  margin-bottom: 36px;
}
