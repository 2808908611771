@import 'styles/_variables';
@import 'styles/_mixins';

.Root {
  @include buttonSecondaryText {
    justify-content: flex-start;
    height: 22px;
  }
}

.amount,
.contributor {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.amount {
  font-size: 10px;
  color: #787D99;
  font-weight: bold;
  background-color: #F0F2FF;
}

.text {
  font-size: 12px;
  color: #787D99;
}
