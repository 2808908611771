@import 'styles/_variables';
@import 'styles/_mixins';

.root {
  padding-bottom: 100px;
}

.title {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 85px;

  @include mobile {
    font-size: 22px;
    margin-bottom: 40px;
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0 50px;

  @include mobile {
    flex-direction: column;
    margin: 0 25px;
  }
}

.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 250px;
  height: 250px;
  font-size: 18px;
  font-weight: bold;
  line-height: 27px;
  color: #493E73;
  text-align: center;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px #493E73CC;

  &:hover > img {
    opacity: 1;

    @include mobile {
      opacity: 0;
    }
  }

  > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 360ms;
  }

  > span {
    position: relative;
  }

  > p {
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 12px;
    width: 100%;
    text-align: center;
    text-decoration: underline;
    font-size: 14px;
    color: #493E73;

    @include mobile {
      bottom: 6px;
      font-size: 11px;
    }
  }

  @include mobile {
    align-self: stretch;
    font-size: 16px;
    line-height: 1.5;
    width: 100%;
    height: 180px;
    padding: 30px 20px 40px;
    margin-bottom: 25px;
  }
}