@import '../../_config';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
  background-color: $alumni-blue-light;
  box-shadow: 0 0 30px 0 #2A1C5B66;
  border-radius: 10px;
  text-align: center;
  padding: 100px 20px;
  font-size: 36px;
  font-family: $alumni-font-family-title;

  > span {
    max-width: 240px;
  }
}

.checkIcon {
  background-image: url('./check.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 88px;
  height: 88px;
  margin-bottom: 25px;
}

.submit {
  margin-top: 80px;
}
