
@mixin mobile($maxWidth: 720px) {
    @media (max-width: $maxWidth) {
        @content;
    }
}

@mixin mobileHeaderNavigation($maxWidth: 1000px) {
  @media (max-width: $maxWidth) {
      @content;
  }
}

@mixin mobileProject($maxWidth: 980px) {
    @media (max-width: $maxWidth) {
        @content;
    }
}

@mixin mobileProfile($maxWidth: 980px) {
    @media (max-width: $maxWidth) {
        @content;
    }
}

@mixin mobileCampaignCreate($maxWidth: 1080px) {
    @media (max-width: $maxWidth) {
        @content;
    }
}

@mixin button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 12px;
  cursor: pointer;

  @content;
}

@mixin buttonPrimarySolid {
  @include button;

  background-color: $color-primary;
  color: #fff;
  font-weight: bold;
  border-radius: 10px;
  height: 35px;

  &:hover {
    background-color: rgba($color-primary, 0.8);
  }

  &:active {
    background-color: rgba($color-primary, 0.6);
  }

  @content;
}

@mixin buttonPrimarySolidDark {
  @include button;

  background-color: $color-primary-dark;
  color: #fff;
  font-weight: bold;
  border-radius: 10px;

  &:hover {
    background-color: rgba($color-primary-dark, 0.8);
  }

  &:active {
    background-color: rgba($color-primary-dark, 0.6);
  }

  @content;
}

@mixin buttonPrimaryHollow {
  @include button;

  background-color: #fff;
  color: $color-primary;
  box-shadow: 0 0 0 1px $color-primary;
  border-radius: 10px;
  font-weight: bold;
  height: 35px;

  &:hover {
    background-color: #F4F7FF;
  }

  &:active {
    background-color: #ECF1FF;
  }

  @content;
}

@mixin buttonPrimaryBackgroundFade {
  @include button;

  color: #787D99;
  border-radius: 7px;

  &:hover {
    background-color: #F4F7FF;
  }

  &:active {
    background-color: #ECF1FF;
  }

  @content;
}

@mixin buttonPrimaryText {
  @include button;

  color: #787D99;

  &:hover,
  &:active {
    color: #4B579A;
  }

  @content;
}

@mixin buttonSecondaryText {
  @include button;

  color: #787D99;
  font-weight: normal;

  &:hover {
    text-decoration: underline;
  }

  &:active {
    color: #4B579A;
  }

  @content;
}

@mixin inputPlaceholder {
  position: absolute;
  top: 0;
  font-size: 14px;
  font-family: $Input-font-family;
  color: #787D99;
  pointer-events: none;
  transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  transform-origin: 0 0;
  background-color: #fff;
  border-radius: 10px;
  // keep the left padding in sync with the transformX
  transform: translate(-8px, 12px);
  padding: 0 8px;
  white-space: nowrap;

  &__isUp {
    transform: translate(0, -8px) scale(0.75);
  }

  @content;
}

@mixin inputValue {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  color: #291E6E;
  height: 24px;
  line-height: 1;
  font-family: $Input-font-family;
  border-radius: 10px;
  padding: 0 12px;
  box-shadow: 0 0 0 1px rgba(#787D99, 0.6);
  background-color: rgba(173, 180, 222, 0.4);

  @content;
}

@mixin modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #D2DAFF;
  animation-duration: 230ms;
  animation-timing-function: ease-out;
  animation-delay: 0;
  animation-iteration-count: 1;
  animation-name: modalOverlay;
  animation-fill-mode: forwards;

  @keyframes modalOverlay {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.8;
    }
  }
}
