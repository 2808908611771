@import 'styles/_variables';
@import 'styles/_mixins';

.RootContainer {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.body {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.avatar {
  margin-right: 5px;
}

.content {
  position: relative;
  background-color: #F5F7FD;
  border-radius: 10px;
  font-size: 13px;
  color: #4B4B4B;
  line-height: 1.25;
  padding: 9px 18px;
  word-break: break-all;
  word-wrap: break-word;

  &_name {
    font-size: 12px;
    font-weight: bold;
    color: #5A42EF;
    margin-right: 6px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.likes {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: -15px;
  right: 8px;;
  font-size: 15px;
  padding: 3px 8px 3px 3px;
  color: #797979;
  font-weight: bold;
  background-color: #fff;
  box-shadow: 1px 1px 7px rgba(#000, 0.2);
  border-radius: 30px;

  @include mobile {
    font-size: 12px;
  }

  &_loader {
    padding: 5px;
    margin-right: 5px;
    width: 24px !important;
    height: 24px !important;

    @include mobile {
      padding: 3px;
      width: 18px !important;
      height: 18px !important;
    }
  }

  img {
    margin-right: 5px;
    width: 24px;
    height: 24px;

    @include mobile {
      width: 18px;
      height: 18px;
    }
  }
}

.menu {
  display: flex;
  align-items: center;
  font-size: 10px;
  line-height: 1;
  margin-top: 2px;
  margin-left: 13px;

  &_line {
    margin: 0 5px;
    color: #A0A0A0;
  }
}

.like,
.comment {
  @include buttonSecondaryText {
    color: #5A42EF;
    height: 20px;
    padding: 0 5px;
    font-size: 10px;
  }
}

.like {
  min-width: 50px;

  &_loader {
    padding: 5px;
    margin-right: 5px;
  }
}

.time {
  color: #A0A0A0;
}

.Input {
  margin-top: 5px;
}

.responses {
  margin-top: 10px;
}

.responseCount {
  @include buttonSecondaryText {
    align-self: flex-start;
    justify-content: flex-start;
    font-size: 10px;
    color: #5A42EF;
    margin-top: 6px;
    margin-left: 66px;

    &_loader,
    img {
      margin-right: 9px;
    }
  }
}
