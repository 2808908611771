@import 'styles/_variables';
@import 'styles/_mixins';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px;
  font-size: 18px;
  font-weight: bold;
  color: #000;
}