@import 'styles/_variables';
@import 'styles/_mixins';

.Root {
  position: fixed;
  top: $AppBar-height;
  left: 0;
  width: 100%;
  height: calc(100% - $AppBar-height);
  background-color: rgba(29, 26, 52, 0.8);
  z-index: 999999;

  @include mobile {
    top: $AppBar-height-mobile;
    height: calc(100% - $AppBar-height-mobile);
  }
}

.body {
  background-color: #fff;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
  margin: 50px auto;
  padding: 25px;
}

.text {
  color: #291E6E;
  font-size: 16px;
  line-height: 20px;
  text-align: justify;

  img {
    display: inline;
    vertical-align: middle;
    margin-right: 5px;
  }
}

.title {
  text-align: center;
  color: #291E6E;
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
  margin-bottom: 30px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.buttonAccept {
  @include buttonPrimarySolid {
    height: 35px;
    width: 123px;
  }
}
