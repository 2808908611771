@import 'styles/_variables';
@import 'styles/_mixins';

.root {
  display: flex;
  flex-direction: column;
}

.item {
  display: flex;
  cursor: pointer;
  color: #4B579A;
  margin-bottom: 25px;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    color: #5A42EF;

    > div {
      &:first-child {
        > div {
          opacity: 0;
        }
      }
    }
  }

  > div {
    &:first-child {
      position: relative;
      min-width: 28px;
      height: 28px;

      > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      > div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(75, 87, 154, 0.7);
        border-radius: 5px;
        opacity: 1;
        transition: opacity 500ms;
      }
    }

    &:last-child {
      display: flex;
      flex-direction: column;
      margin-left: 15px;

      > strong {
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 5px;
      }

      > span {
        font-size: 12px;
        color: #4B579A !important;
      }
    }
  }
}