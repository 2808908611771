@import '../__config';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  background-color: $skema-beige;
  
  > img {
    max-height: 32px;
  }
}

