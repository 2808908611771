@import 'styles/_variables';
@import 'styles/_mixins';

.RootContainer {
  display: flex;
  flex-direction: column;
}

.Text {
  margin-bottom: 15px;
}

.Checkbox {
  margin-left: 20px;

  &__isCompany {
    margin-bottom: 20px;
  }

  &_link {
    margin-left: 5px;
    color: #5A42EF;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.buttonForgotPassword {
  align-self: flex-start;
  margin: 20px 20px 10px;
  font-size: 14px;
  color: #5A42EF;
  cursor: pointer;

  @include mobile {
    margin-top: 10px;
  }

  &:hover {
    text-decoration: underline;
  }
}

.login,
.register {
  &_button {
    @include buttonPrimarySolid {
      min-width: 123px;
      height: 35px;
      font-size: 12px;
      font-weight: bold;
      align-self: flex-end;

      @include mobile {
        margin-top: 20px;
        width: 100%;
      }
    }
  }
}

.login {
  display: flex;
  justify-content: flex-end;
}

.register {
  display: flex;
  align-items: center;

  @include mobile {
    flex-direction: column;
  }

  &_button {
    margin-left: 30px;

    @include mobile {
      margin-left: 0;
    }
  }
}

.buttonSetLogin {
  display: flex;
  align-self: flex-end;
  height: 30px;
  font-size: 14px;
  font-weight: bold;
  margin-top: 50px;
  color: #787D99;

  &:hover {
    text-decoration: underline;
  }

  span {
    margin-left: 6px;
    color: #5A42EF;
  }

  @include mobile {
    align-self: center;
    text-align: center;
    margin-top: 20px;
    font-size: 12px;
    color: #787D99;
  }
}
