@import 'styles/_variables';
@import 'styles/_mixins';

// .root {}

.SocialLogin {
  max-width: 520px;
  margin: 0 auto;
}

.orEmail {
  margin: 25px 0;
  font-size: 12px;
  color: #797979;
}

.input {
  max-width: 356px;
  width: 100%;
  margin: 0 auto 15px;
}

.login {
  @include buttonPrimarySolid {
    align-self: center;
    margin-top: 25px;
    font-size: 12px;
    font-weight: bold;
    width: 123px;
    height: 35px;
  }
}