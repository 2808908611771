@import 'styles/_variables';
@import 'styles/_mixins';

.Root {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 100%;
  margin-top: 25px;
  box-shadow: 0 20px 20px rgba(#000, 0.25);
  background-color: #fff;
  border-radius: 10px;
  z-index: 1;
}

.arrow {
  position: absolute;
  top: -15px;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  transform: rotateZ(45deg);
  background-color: #fff;
  z-index: 1;
}

.items {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  padding: 0 25px;
  z-index: 2;

}
.item {
  margin: 0 15px;
}

.copy {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #CFD2E3;
  width: 100%;
  height: 36px;
  color: #5A42EF;
  font-size: 14px;
  font-weight: bold;
}
