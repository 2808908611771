@import 'styles/_variables';
@import 'styles/_mixins';

.RootContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  padding: 17px 0;
  border: 1px solid rgba(#000, 0.1);
  border-radius: 10px;

  @include mobile {
    flex-direction: column;
    padding: 10px 0 5px;
  }
}

.label {
  position: absolute;
  top: -9px;
  padding: 0 20px;
  font-size: 12px;
  font-weight: bold;
  color: #787D99;
  background-color: #fff;

  @include mobile {
    font-size: 11px;
    top: -7px;
  }
}

.google,
.facebook {
  display: flex;
  align-items: center;
  flex: 1;
  max-width: 205px;
  height: 35px;
  min-height: 35px;
  padding: 0 20px;
  font-size: 11px;
  font-weight: bold;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;

  @include mobile {
    justify-content: center;
    align-self: stretch;
    max-width: none;
    margin: 5px 15px !important;
  }

  img {
    margin-right: 10px;
  }
}
.google {
  margin-left: 27px;
  margin-right: 8px;
  background-color: #CF5744;
}
.facebook {
  margin-right: 27px;
  margin-left: 8px;
  background-color: #415993;
}
