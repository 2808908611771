
.root {
  display: flex;
  flex-direction: column;
  padding: 25px 20px;
  margin: 20px;
  font-weight: 600;
  background-color: #fff;
  box-shadow: 0 0 30px 0 #2A1C5B66;
  border-radius: 10px;
  font-size: 14px;

  > strong {
    font-weight: 900;
    font-size: 16px;
  }
}

.spacing {
  height: 15px;
}

.submit {
  margin-top: 32px;
}