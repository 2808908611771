@import 'styles/_variables';
@import 'styles/_mixins';

.toast {
  border-radius: 6px;
  padding: 10px 15px;
  font-size: 15px;
  font-weight: bold;
}

.success {
  background-color: #96c93d;
  background: linear-gradient(to right, #00b09b, #96c93d);
}

.info {
  background-color: #4AE0C6;
  background: linear-gradient(to right, #4AE0C6, #1EBFA3);
}

.error {
  background-color: #c17272;
  background: linear-gradient(to right, #f42525, #c17272);
}
