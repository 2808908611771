@import 'styles/_variables';
@import 'styles/_mixins';

.title {
  font-size: 12px;
  font-weight: bold;
  color: #787D99;
  margin-bottom: 20px;
}

.list {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 300px;
  width: 100%;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 50%;
  margin-bottom: 30px;
  line-height: 1;

  &_name {
    font-size: 12px;
    font-weight: bold;
    margin: 10px 0 5px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  &_amount {
    font-size: 10px;

    span {
      font-weight: bold;
      color: #5a42EF;
    }
  }
}
