@import 'styles/_variables';
@import 'styles/_mixins';

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(#fff, 0.8);
  max-width: 940px;
  margin: 150px auto 50px;
  border-radius: 10px;
  overflow: hidden;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 75px;
  background-color: #fff;
  color: #787D99;
  font-size: 24px;
  font-weight: bold;
}

.body {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 80px;
  width: 100%;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 45px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 15px 0px #00000026;
}

.titleConnect {
  font-size: 16px;
  font-weight: bold;
  color: #4B4B4B;
  margin-bottom: 30px;
}

.input {
  max-width: 360px;
  margin-bottom: 12px
}

.checkbox {
  div {
    font-size: 12px;

    a {
      margin-left: 5px;
      text-decoration: none;
      color: #5A42EF;
    }
  }
}

.login {
  @include buttonPrimarySolid {
    margin-top: 25px;
    font-size: 12px;
    font-weight: bold;
    width: 123px;
    height: 35px;
  }
}