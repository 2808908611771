@import 'styles/_variables';
@import 'styles/_mixins';

.RootContainer {
  &_isCommentsVisible {
    padding-bottom: 15px;
  }
}

.showMoreComments,
.comments,
.Input {
  padding: 0 35px;

  @include mobile {
    padding: 0 10px;
  }
}

.showMoreComments {
  margin-bottom: 20px;
  font-size: 12px;
  color: #787D99;
  font-weight: bold;
}

.loader {
  margin: 0 35px 15px;

  @include mobile {
    margin: 0 15px 10px;
  }
}

.line {
  height: 1px;
  background-color: rgba(#787D99, 0.5);
  margin: 0 60px 15px;

  @include mobile {
    margin: 0 30px 15px;
    background-color: rgba(#787D99, 0.2);
  }
}
