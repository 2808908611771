@import 'styles/_variables';
@import 'styles/_mixins';

.modal {
  max-width: 620px;
}

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #787D99;
  width: 100%;
}

.title {
  font-size: 18px;
  font-weight: bold;
  margin-top: 12px;
  margin-bottom: 15px;
}

.subtitle {
  font-size: 16px;
  margin-bottom: 25px;
  line-height: 24px;
  max-width: 420px;
  color: #5A42EF;
}

.copytext {
  display: flex;
  align-items: center;
  color: #787D99;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 25px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  > img:nth-child(1) {
    margin-left: 7px;
  }

  > img:nth-child(2) {
    margin-left: 22px;
  }
}

.textarea {
  width: 100%;
  resize: none;
  min-height: 320px;
  max-height: 320px;
  padding: 20px;
  border-radius: 10px;
  font-size: 15px;
  color: #787D99;
  border: 1px solid #C8C8C8;
  margin-bottom: 30px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  height: 34px;
  padding: 0 15px;
  background-color: #5A42EF;
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  text-decoration: none;
  margin-bottom: 15px;
}