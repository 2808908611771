@import 'styles/_variables';
@import 'styles/_mixins';

.Modal {
  background-color: transparent;
  padding: 0;
  max-width: 500px;
  background-color: #fff;
  padding: 20px;
}

.SignatureCanvas {
  border: 1px solid gray;
  margin-bottom: 20px;
  border-radius: 10px;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cancel,
.clear {
  @include buttonPrimaryHollow {
    width: 100px;
    margin: 0 10px;
  }
}

.submit {
  @include buttonPrimarySolid {
    width: 100px;
    margin: 0 10px;
  }
}
