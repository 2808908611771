@import 'styles/_variables';
@import 'styles/_mixins';

.RootContainer {
  border-radius: 5px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
  }
}
