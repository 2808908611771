@import 'styles/_variables';
@import 'styles/_mixins';

.Modal {
  max-width: 600px;
}

.title {
  color: #787D99;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.body {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &__isActiveFile {
    flex-direction: column;
    width: 100%;
  }
}

.Input {
  margin: 10px;

  &__isHidden {
    display: none;
  }
}

.progress {
  margin-top: 20px;
  width: 200px;
  height: 10px;
  border-radius: 10px;
  background-color: lightgray;

  &_bar {
    border-radius: inherit;
    min-width: 10px;
    height: 100%;
    transition: width 320ms;
    background-color: #5a42ef;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.buttonCancel {
  @include buttonPrimaryHollow {
    width: 124px;
    height: 34px;
    margin-right: 20px;
  }
}

.buttonSubmit {
  @include buttonPrimarySolid {
    width: 124px;
    height: 34px;
  }
}
