@import '../../_config';

.root {
  display: flex;
  flex-direction: column;
  background-color: $skema-beige;
  margin: 20px 15px;
  padding: 30px 20px;
  border-radius: 10px;
}

.title {
  font-size: 29px;
  font-weight: 900;
  text-align: center;
}

.subtitle {
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  margin: 10px 0 25px;
}

.campaigns {
  display: flex;
  flex-direction: column;
}
