@import 'styles/_variables';
@import 'styles/_mixins';

$padding-horizontal: 35px;

.RootContainer {
  display: flex;
  flex-direction: column;
  padding: 15px 0;

  @include mobile {
    padding-top: 5px;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 #{$padding-horizontal - 10px} 0 $padding-horizontal;

  &__isLight {
    padding-right: 10px;
  }

  @include mobile {
    padding: 0 5px 0 10px;
  }

  &_left,
  &_right {
    display: flex;
    align-items: center;
  }

  &_left {
    cursor: pointer;
  }

  // &_right {}
}

.avatar {
  margin-right: 20px;
}

.name {
  font-size: 14px;
  font-weight: bold;
  color: #4B4B4B;
  line-height: 1;
  margin-bottom: 5px;
}

.city {
  font-size: 12px;
  color: #4B4B4B;
  line-height: 1;
}

.time {
  font-size: 12px;
  color: #787D99;

  @include mobile {
    display: none;
  }
}

.menu {
  margin-left: 20px;
  width: 40px;
  height: 40px;
  border-radius: 40px;
}

.description {
  margin-top: 12px;
  padding: 0 35px 0 $padding-horizontal;
  font-size: 14px;
  color: #4B4B4B;
  text-align: justify;

  @include mobile {
    padding: 0 10px;
  }
}
