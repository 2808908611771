@import 'styles/_variables';
@import 'styles/_mixins';

// .root {}

.input {
  max-width: 356px;
  width: 100%;
  margin: 0 auto 15px;
}

.login {
  @include buttonPrimarySolid {
    align-self: center;
    margin-top: 25px;
    font-size: 12px;
    font-weight: bold;
    width: 123px;
    height: 35px;
  }
}