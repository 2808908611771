@import 'styles/_variables';
@import 'styles/_mixins';

.Root {
  position: relative;
  width: 100%;
  background-color: #fff;
  border-radius: $Input-border-radius;
  border: 1px solid rgba(#787D99, 0.5);

  &:hover,
  &__isFocus {
    border-color: $color-primary;
  }
}

.input {
  position: relative;
  display: block;
  width: 100%;
  height: 42px;
  font-size: $Input-font-size;
  padding: 0 $Input-padding;
  font-family: $Input-font-family;
  border-radius: inherit;
  border: 0 none;
  background-color: #fff;
  z-index: 5;

  &__isPrefix {
    padding-left: $Input-padding + 25px;
  }

  @include mobile {
    padding: 0 $Input-padding-mobile;
  }

  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.placeholder {
  @include inputPlaceholder {
    left: $Input-padding;
    z-index: 10;

    &__isPrefix {
      left: $Input-padding + 25px;
    }

    @include mobile {
      left: $Input-padding-mobile;
    }
  }
}

.info {
  position: absolute;
  top: 100%;
  left: -1px;
  width: calc(100% + 2px);
  font-size: 10px;
  color: #787D99;
  margin-top: -6px;
  padding: 10px 5px 5px;
  background-color: #F0F2FF;
  border: 1px solid rgba(#787D99, 0.5);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.prefix {
  position: absolute;
  top: 14px;
  left: 15px;
  font-size: 12px;
  z-index: 12;
}

.InputError {
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 100%;
  z-index: 10;
}
